import { SET_SELECTED_FILE_MILES } from '../actions/selected-files-actions';

const initialState = {
	selectedFileMiles: 0
};
function selectedFileReducer(state = initialState, action) {
	switch (action.type) {
		case SET_SELECTED_FILE_MILES:
			return { ...state, selectedFileMiles: action.payload };
		default:
			return state;
	}
}
export default selectedFileReducer;
